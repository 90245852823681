import { Button } from "@mui/base";
import { Alert, AlertTitle, Stack } from "@mui/material";
import React from "react";
import styles from "./AlertStack.module.css";

const ALERT_BORDER_COLORS = {
  success: "#1F741F",
  error: "#800000",
};

{
  /* 
    NOTE: The @messages received by this component should
    follow the structure:

        message {
            type: "success" | "error" | ...
            messageBody: <Array> where each element represents a paragraph/component
        }

*/
}
const AlertStack = ({ messages, deleteMessage }) => {
  return (
    <div className={styles.alert_stack__div}>
      {messages && messages.length ? (
        <Stack sx={{ width: "100%" }} spacing={1}>
          {messages.map((messageItem, index) => {
            return (
              <Alert
                key={index}
                severity={messageItem.type ?? "success"}
                className={styles.success_alert}
                onClose={() => deleteMessage(index)}
                sx={{
                  border: `1px solid ${
                    ALERT_BORDER_COLORS[messageItem.type] ??
                    ALERT_BORDER_COLORS["success"]
                  }`,
                }}
              >
                <AlertTitle className={styles.alert_title}>
                  {messageItem.type ?? "Success"}
                </AlertTitle>

                {messageItem.messageBody && messageItem.messageBody.length ? (
                  messageItem.messageBody.map(
                    (messageComponent, messageComponentIndex) => {
                      return (
                        <p
                          key={messageComponentIndex}
                          className={styles.alert_body_component}
                        >
                          {messageComponent}
                        </p>
                      );
                    }
                  )
                ) : (
                  <></>
                )}
              </Alert>
            );
          })}
        </Stack>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AlertStack;
