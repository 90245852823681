import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stepsList: [],
  nextToken: null,
  lastPageFetch: 0,
};

export const stepsReducer = createSlice({
  name: "steps",
  initialState,
  reducers: {
    updateStepList: (state, action) => {
      state.stepsList = action.payload.stepsList;
      state.nextToken = action.payload.nextToken;
      state.lastPageFetch = action.payload.lastPageFetch;
    },
  },
});

export const { updateStepList } = stepsReducer.actions;

export default stepsReducer.reducer;
