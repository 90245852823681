import { Typography } from "@mui/material";
import React from "react";

export default function ClusterIDCellRender(props) {
  const { hasFocus, value } = props;

  const formatValue = () => {
    return value.includes("non-existing-cluster-row-") ? "" : value;
  };

  return <Typography variant="inherit">{formatValue()}</Typography>;
}
