export const LINKS_MAP = [
  {
    name: "Steps",
    linkTo: "/",
  },
  {
    name: "Add Step",
    linkTo: "/add-step",
  },
  {
    name: "Clusters",
    linkTo: "/clusters",
  },
];

export const VALID_TEXTFIELD_BORDER_COLOR = "#AAAAAA";

export const ACTION_ON_STEP_CREATION_FAILURE = "CONTINUE";

export const TEXTAREA_CHARACTER_LIMIT = 256;

export const PRIORITY_ON_STEP_CREATION = 5;

export const ENVIRONMENT_MAP = {
  dev: "Development",
  test: "Test",
  prod: "Production",
};

export const STEPS_TABLE_HEADER_CELLS = [
  {
    name: "",
    width: "70px",
  },
  {
    name: "Request ID",
    width: "110px",
  },
  {
    name: "Name",
    width: "270px",
  },
  {
    name: "Status",
    width: "106px",
  },
  {
    name: "Context",
    width: "240px",
  },
  {
    name: "Logs",
    width: "180px",
  },
  {
    name: "Elapsed Time",
    width: "141px",
  },
];

const CHIP_SUCCESS_COLOR_MAP = {
  color: "#1F741F",
  backgroundColor: "#EBFAEB",
};

const CHIP_IN_PROCESSS_COLOR_MAP = {
  color: "#B36B00",
  backgroundColor: "#FFF5E4",
};

const CHIP_FAILURE_COLOR_MAP = {
  color: "#800000",
  backgroundColor: "#FFE6E6",
};

export const STEP_STATUS_LIST = [
  "FAILED",
  "ACCEPTED",
  "SUBMITTED",
  "COMPLETED",
  "CANCELLED",
  "INTERRUPTED",
  "DECLINED",
];

export const CHIP_COLOR_MAP = {
  COMPLETED: CHIP_SUCCESS_COLOR_MAP,
  ACCEPTED: CHIP_IN_PROCESSS_COLOR_MAP,
  SUBMITTED: CHIP_IN_PROCESSS_COLOR_MAP,
  CANCELLED: CHIP_FAILURE_COLOR_MAP,
  FAILED: CHIP_FAILURE_COLOR_MAP,
  INTERRUPTED: CHIP_FAILURE_COLOR_MAP,
  DECLINED: CHIP_FAILURE_COLOR_MAP,
};

export const DEFAULT_STEP_STATUS = "COMPLETED";

export const STEPS_ROWS_PER_PAGE_LIST = ["10", "20", "50"];

export const CLUSTERS_ROWS_PER_PAGE_LIST = ["10", "20", "50"];
