import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { VALID_TEXTFIELD_BORDER_COLOR } from "../../../utils/constants";
import { OutlinedInput } from "@mui/material";

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function FormSelectField({
  label,
  labelID,
  value,
  onChange,
  options,
  modalWidth,
  height = 40,
  size = "medium",
}) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <InputLabel id={labelID}>{label}</InputLabel>
      <Select
        labelId={labelID}
        id={labelID}
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected}
        MenuProps={MenuProps}
        size={size}
        sx={{
          width: modalWidth,
          height: height,
          borderRadius: 0,
          bgcolor: "white",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: VALID_TEXTFIELD_BORDER_COLOR,
            borderRadius: "0px",
          },
        }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
