import React, { useEffect, useState } from "react";
import styles from "./CreateStep.module.css";
import { Typography, Button, Alert, AlertTitle } from "@mui/material";
import FormTextField from "../../components/FormFields/FormTextField/FormTextField";
import "../../global.css";
import { formatArguments } from "../../utils/shared-functions";
import {
  ACTION_ON_STEP_CREATION_FAILURE,
  PRIORITY_ON_STEP_CREATION,
} from "../../utils/constants";
import { createStep } from "../../services/StepsService";
import { useSelector } from "react-redux";

const CreateStep = ({
  newName = "",
  newJarLocation = "",
  newJarArgs = "",
  newPermissionRole = "",
}) => {
  const username = useSelector((state) => state.user.username);

  const [name, setName] = useState(newName);
  const [jarLocation, setJarLocation] = useState(newJarLocation);
  const [jarArgs, setJarArgs] = useState(newJarArgs);
  const [permissionRole, setPermissionRole] = useState(newPermissionRole);
  const [message, setMessage] = useState(null);
  const [user, setUsername] = useState(null);

  useEffect(() => {
    setName(newName);
    setJarLocation(newJarLocation);
    setJarArgs(newJarArgs);
    setPermissionRole(newPermissionRole);
  }, [newName, newJarLocation, newJarArgs, newPermissionRole]);

  const createNewStepHelper = async (e) => {
    e.preventDefault();
    const argsArray = formatArguments(jarArgs ?? "");
    const body = JSON.stringify({
      Context: username,
      ExecutionRoleArn: permissionRole,
      Priority: PRIORITY_ON_STEP_CREATION,
      Steps: [
        {
          Name: name,
          ActionOnFailure: ACTION_ON_STEP_CREATION_FAILURE,
          HadoopJarStep: {
            Jar: jarLocation,
            Args: argsArray,
          },
        },
      ],
    });

    await createStep(body, setMessage);
  };

  useEffect(() => {
    if (message === "Success") {
      setName();
      setJarLocation();
      setJarArgs();
      setPermissionRole();
    }
  }, [message]);

  return (
    <div>
      <div className={styles.add_step__form_group}>
        {message === "Success" ? (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Step created successfuly
          </Alert>
        ) : (
          <></>
        )}
        {message === "Failed" ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            There was an error while creating the step. Please check that the
            information below is correct.
          </Alert>
        ) : (
          <></>
        )}
      </div>

      <div className={styles.add_step__form_group}>
        <Typography className={styles.add_step__form_label}>Name</Typography>

        <div className={styles.add_step__form_field}>
          <FormTextField
            value={name}
            setter={setName}
            placeholder="Enter name ..."
            size="small"
          />
        </div>
      </div>

      <div className={styles.add_step__form_group}>
        <Typography className={styles.add_step__form_label}>
          Jar Location
        </Typography>

        <Typography className={styles.add_step__form_sub_label}>
          The JAR location may be a path into S3 or a fully qualified java class
          in the classpath.
        </Typography>

        <div className={styles.add_step__form_field}>
          <FormTextField
            value={jarLocation}
            setter={setJarLocation}
            placeholder="s3://bucket/prefix/object"
            size="small"
          />
        </div>
      </div>

      <div className={styles.add_step__form_group}>
        <Typography className={styles.add_step__form_label}>
          Arguments
        </Typography>

        <Typography className={styles.add_step__form_sub_label}>
          These are passed to the main function in the JAR. If the JAR does not
          specify a main class in its manifest file, you can specify another
          class name as the first argument.
        </Typography>

        <div className={styles.add_step__form_field}>
          <FormTextField
            value={jarArgs}
            setter={setJarArgs}
            placeholder="bash -c “aws s3 cp s3://DOC-EXAMPLE-BUCKET/my_script.sh .”"
            size="small"
            multiline={true}
            rows={5}
            hasResize={true}
          />
        </div>
      </div>

      <div className={styles.add_step__form_group}>
        <Typography className={styles.add_step__form_label}>
          Permission
        </Typography>

        <div className={styles.add_step__form_field}>
          <FormTextField
            value={permissionRole}
            setter={setPermissionRole}
            placeholder="Enter permission ..."
            size="small"
          />
        </div>
      </div>

      <div className={styles.add_step__buttons_section}>
        <div className={styles.buttons_wrapper}>
          <div className={styles.button_div}>
            <Button variant="outlined" className={styles.cancel_button}>
              Clear
            </Button>
          </div>

          <div className={styles.button_div}>
            <Button
              variant="contained"
              onClick={(e) => createNewStepHelper(e)}
              className={styles.add_step_button}
            >
              Add Step
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateStep;
