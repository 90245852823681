import { getUrl } from "../utils/shared-functions";
import { getAWSClient } from "./AuthService";
import { updateStepList } from "../redux/slices/steps-sclice";

export const createStep = async (body, setMessage) => {
  try {
    const [aws, domain] = await Promise.all([getAWSClient(), getUrl()]);
    const url = `${domain}/SubmitSparkStep`;
    const res = await aws.fetch(url, { method: "POST", body });

    setMessage(res.status === 202 ? "Success" : "Failed");
  } catch (e) {
    console.log("createStep failed", e);
    setMessage("Failed");
  }
};

export const getSteps = async ({
  token = null,
  dispatch,
  prevSteps,
  nextPage,
  searchBody = {},
}) => {
  try {
    const [aws, domain] = await Promise.all([getAWSClient(), getUrl()]);

    searchBody["limit"] = parseInt(searchBody["limit"] ?? "10");
    searchBody["nextToken"] = token;
    const requestBody = JSON.stringify(searchBody);
    const url = `${domain}/QuerySparkSteps`;

    const res = await aws.fetch(url, {
      method: "POST",
      body: requestBody,
    });

    const result = await res.json();
    const steps = result.sparkSteps ?? [];

    const updatedStepsList = token ? prevSteps.concat(steps) : steps;
    const nextToken = result.nextToken ?? null;
    const lastPageFetch = steps.length ? nextPage : nextPage - 1;

    dispatch(
      updateStepList({
        stepsList: updatedStepsList,
        nextToken,
        lastPageFetch,
      })
    );
  } catch (e) {
    console.log("getSteps failed", e);
    dispatch(
      updateStepList({ stepsList: [], nextToken: null, lastPageFetch: 0 })
    );
  }
};

export const getStepLogs = async (stepID, logType, setLogs) => {
  try {
    const [aws, domain] = await Promise.all([getAWSClient(), getUrl()]);
    const url = `${domain}/GetSparkStepLog?id=${stepID}&type=${logType}`;
    const res = await aws.fetch(url, { method: "GET" });

    const resultFormatted = await res.text();
    setLogs(resultFormatted);
  } catch (e) {
    console.log("getStepLogs failed", e);
  }
};

export const getStep = async (stepID, setStepInfo) => {
  try {
    const [aws, domain] = await Promise.all([getAWSClient(), getUrl()]);
    const url = `${domain}/GetSparkStepDetails?id=${stepID}`;
    const res = await aws.fetch(url, { method: "GET" });

    const result = await res.json();
    setStepInfo(result);
  } catch (e) {
    console.log("getStep failed", e);
  }
};
