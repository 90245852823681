import React, { useState } from "react";
import style from "./LinkTooltip.module.css";
import { Tooltip } from "@mui/material";
import { handleCopy } from "../../utils/shared-functions";

const COPY_SUCCESS_MESSAGE = "copied!";
const COPY_PROMPT_MESSAGE = "click to copy";

const LinkTooltip = ({ displayText, copyText }) => {
  const [tooltipTitle, setTooltipTitle] = useState(COPY_PROMPT_MESSAGE);

  const handleClick = () => {
    handleCopy(copyText);
    setTooltipTitle(COPY_SUCCESS_MESSAGE);
  };

  const handleMouseLeave = () => {
    setTooltipTitle(COPY_PROMPT_MESSAGE);
  };

  return (
    <Tooltip title={tooltipTitle} className={style.link_tooltip}>
      <span
        className={style.link_tooltip__span}
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}
      >
        {displayText}
      </span>
    </Tooltip>
  );
};

export default LinkTooltip;
