import { Chip } from "@mui/material";
import React from "react";
import {
  CLUSTER_CHIP_COLOR_MAP,
  DEFAULT_CLUSTER_STATUS,
} from "../../../../../utils/clusters-constants";

export default function ClusterStatusCellRender(props) {
  const { hasFocus, value } = props;
  return value ? (
    <Chip
      label={value}
      size="small"
      sx={{
        backgroundColor:
          CLUSTER_CHIP_COLOR_MAP[value ?? DEFAULT_CLUSTER_STATUS]
            .backgroundColor,
        color: CLUSTER_CHIP_COLOR_MAP[value ?? DEFAULT_CLUSTER_STATUS].color,
        width: "100px",
        borderRadius: "0px",
      }}
    />
  ) : (
    <></>
  );
}
