import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: "",
};

export const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUsername: (state, action) => {
      state.username = action.payload.username;
    },
  },
});

export const { updateUsername } = userReducer.actions;

export default userReducer.reducer;
