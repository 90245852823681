import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  gridFilterModelSelector,
  useGridSelector,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import { VALID_TEXTFIELD_BORDER_COLOR } from "../../../../utils/constants";
import {
  CLUSTER_DEAD_STATES,
  CLUSTER_LIVE_STATES,
} from "../../../../utils/clusters-constants";

const getDefaultFilter = (field) => ({ field, operator: "contains" });

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function ClusterStatusFilter(props) {
  const { colDef } = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = useMemo(
    () => filterModel.items?.filter(({ field }) => field === colDef.field),
    [colDef.field, filterModel.items]
  );

  const handleChange = useCallback(
    (event) => {
      if (!event.target.value) {
        if (currentFieldFilters[0]) {
          apiRef.current.deleteFilterItem(currentFieldFilters[0]);
          apiRef.current.deleteFilterItem({
            field: "state",
            operator: "contains",
          });
        }
        return;
      }
      const liveStates = event.target.value.includes("LIVE")
        ? CLUSTER_LIVE_STATES
        : [];
      const deadStates = event.target.value.includes("DEAD")
        ? CLUSTER_DEAD_STATES
        : [];
      const statusArray = new Array().concat(liveStates).concat(deadStates);

      apiRef.current.upsertFilterItems([
        {
          ...(currentFieldFilters[0] || getDefaultFilter(colDef.field)),
          value: event.target.value,
        },
        {
          field: "state",
          operator: "contains",
          value: statusArray,
        },
      ]);
    },
    [apiRef, colDef.field, currentFieldFilters]
  );

  const value =
    typeof currentFieldFilters[0]?.value === "string"
      ? currentFieldFilters[0]?.value.split(",")
      : currentFieldFilters[0]?.value ?? [];
  const label = "Filter";

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth>
      <InputLabel
        id="select-cluster-status-label"
        sx={{
          fontSize: 16,
          color: "#a3a3a3",
          height: 40,
          marginTop: -1.5,
          marginLeft: 1,
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="select-cluster-status-label"
        id="select-cluster-status"
        multiple
        input={<OutlinedInput label={"Filter"} />}
        renderValue={(selected) => selected.join(", ")}
        value={value}
        onChange={handleChange}
        MenuProps={MenuProps}
        sx={{
          height: 40,
          borderRadius: 0,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: VALID_TEXTFIELD_BORDER_COLOR,
            borderRadius: "0px",
          },
        }}
      >
        {["LIVE", "DEAD"].map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
