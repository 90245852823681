import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMenuLink } from "../../redux/slices/menu-slice";
import { Button, Typography } from "@mui/material";
import styles from "./Steps.module.css";
import { getSteps } from "../../services/StepsService";
import CustomPagination from "../../components/Pagination/CustomPagination";
import usePagination from "../../hooks/usePagination/usePagination";
import {
  STEP_STATUS_LIST,
  STEPS_ROWS_PER_PAGE_LIST,
} from "../../utils/constants";
import RefreshIcon from "@mui/icons-material/Refresh";
import "../../global.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MultipleSelect from "../../components/MultiSelect/MultiSelect";
import SearchIcon from "@mui/icons-material/Search";
import FormTextField from "../../components/FormFields/FormTextField/FormTextField";
import { decodeURLParams } from "../../utils/shared-functions";
import FormSelectField from "../../components/FormFields/FormSelectField/FormSelectField";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import TableDetailPanel from "../../components/Tables/StepsTable/TableDetailPanel/TableDetailPanel";
import { columns } from "./Columns";

const HYDROS_PROD_CONTEXT = "Hydros:prod";

const StepsPage = () => {
  const stepsFromRedux = useSelector((state) => state.steps.stepsList);
  const paginationTokenFromRedux = useSelector(
    (state) => state.steps.nextToken
  );
  const lastPageFetchFromRedux = useSelector(
    (state) => state.steps.lastPageFetch
  );

  const dispatch = useDispatch();

  const [stepsList, setStepsList] = useState([]);
  const [stepsToDisplay, setStepsToDisplay] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [lastPage, setLastPage] = useState(0);

  // Amount of rows per page variables
  const [amountOfRows, setAmountOfRows] = useState("10");

  // Pagination variables
  const [page, setPage] = useState(1);
  const paginatedSteps = usePagination(
    stepsToDisplay,
    parseInt(amountOfRows) ?? 10
  );
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [canDisplayPagination, setCanDisplayPagination] = useState(false);
  const [canRefresh, setCanRefresh] = useState(false);

  // Select step variables
  const [selectedStepID, setSelectedStepID] = useState(null);

  // Searching variables
  const [searchBody, setSearchBody] = useState({});

  // Filter variables
  const [stepContextFilter, setStepContextFilter] = useState("");
  const [requestIDFilter, setRequestIDFilter] = useState("");
  const [stepNameFilter, setStepNameFilter] = useState("");
  const [stepStatusFilter, setStepStatusFilter] = useState([]);

  const STEPS_FILTER_MAP = {
    contextContains: {
      type: "string",
      setter: setStepContextFilter,
    },
    requestId: {
      type: "string",
      setter: setRequestIDFilter,
    },
    nameContains: {
      type: "string",
      setter: setStepNameFilter,
    },
    requestStatusIn: {
      type: "array",
      setter: setStepStatusFilter,
    },
    limit: {
      type: "string",
      setter: setAmountOfRows,
    },
  };

  const parseAmountOfRows = () => parseInt(amountOfRows) ?? 10;

  // URL Params (filter url params) variables
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(updateMenuLink({ linkName: "Steps" }));

    // Get url params if they are provided
    const params = new URLSearchParams(location.search);
    let urlParams = decodeURLParams(params, STEPS_FILTER_MAP);

    async function fetchSteps({ newSearchBody } = {}) {
      await getSteps({
        token: null,
        dispatch: dispatch,
        prevSteps: stepsList,
        nextPage: 1,
        searchBody: newSearchBody,
      });
      setCanDisplayPagination(true);
    }

    if (!urlParams.hasOwnProperty("limit")) {
      urlParams["limit"] = "10";
      STEPS_FILTER_MAP["limit"].setter("10");
    }
    if (!urlParams || !Object.keys(urlParams).length) {
      fetchSteps();
    } else {
      // Update the filters with the values
      // from the url route
      for (const [key, value] of Object.entries(urlParams)) {
        if (STEPS_FILTER_MAP[key]) {
          STEPS_FILTER_MAP[key].setter(value);
        }
      }
      fetchSteps({ newSearchBody: urlParams });
    }
  }, []);

  useEffect(() => {
    // TODO: utilize server side pagination provided through MUI Data Grid in the future
    const nextPageTemp =
      paginationTokenFromRedux !== nextToken ? page + 1 : page;
    setStepsList(stepsFromRedux);
    paginatedSteps.updateData(stepsFromRedux);
    paginatedSteps.updateItemsPerPage(parseAmountOfRows());
    setStepsToDisplay(
      stepsFromRedux.slice(
        parseAmountOfRows() * (nextPageTemp - 1),
        parseAmountOfRows() * nextPageTemp
      )
    );
    setNextToken(paginationTokenFromRedux);

    // If there are less next steps than parseAmountOfRows
    if (page > 1 && stepsFromRedux.length <= (page - 1) * parseAmountOfRows()) {
      paginatedSteps.jump(page);
      setPage(page);
      setHasNextPage(false);
      setStepsToDisplay(Array(parseAmountOfRows()).fill({}));
    }
  }, [stepsFromRedux, paginationTokenFromRedux]);

  useEffect(() => {
    setLastPage(lastPageFetchFromRedux);
    setHasNextPage(
      nextToken || (lastPageFetchFromRedux && lastPageFetchFromRedux > page)
    );
  }, [lastPageFetchFromRedux, nextToken, page]);

  useEffect(() => {
    setCanRefresh(true);
  }, [stepsToDisplay]);

  const onChangePageNumber = async (event, newPage) => {
    setLoading(true);
    try {
      setCanDisplayPagination(false);
      setCanRefresh(false);
      // If jumping to the next page, then fetch new steps
      if (newPage > page && newPage > lastPage) {
        await getSteps({
          token: nextToken,
          dispatch: dispatch,
          prevSteps: stepsList,
          nextPage: newPage,
          searchBody: searchBody,
        });
      } else {
        setStepsToDisplay(
          stepsFromRedux.slice(
            parseAmountOfRows() * (newPage - 1),
            parseAmountOfRows() * newPage
          )
        );
      }

      setPage(newPage);
      paginatedSteps.jump(newPage);
      setCanDisplayPagination(true);
    } finally {
      setLoading(false);
    }
  };

  const onRefresh = async () => {
    setCanRefresh(false);
    window.location.reload();
  };

  const handleLinkClick = (event, isDisabled) => {
    if (isDisabled) event.preventDefault();
  };

  const handleCheckboxClick = (event): [string] => {
    // If the list has 0 or more than 1 item, set selectedStepID to null
    if (event.length !== 1) {
      setSelectedStepID(null);
      return;
    }

    const stepID = event[0];

    if (stepID === selectedStepID) {
      setSelectedStepID(null);
      return;
    }

    setSelectedStepID(stepID);
  };

  const onFilterChange = (filterName, newValue) => {
    const params = new URLSearchParams(location.search);
    const newSearchBody = { ...searchBody };

    // Delete any existing old value of the filter
    params.delete(filterName);

    // Check if filter is being added/modified or
    // completely deleted
    if (newValue.length) {
      if (typeof newValue === "string") {
        params.append(filterName, newValue);
      } else {
        newValue.forEach((value) => params.append(filterName, value));
      }
      newSearchBody[filterName] = newValue;
    } else {
      delete newSearchBody[filterName];
    }

    setSearchBody(newSearchBody);
    updateNavigationHelper({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const updateNavigationHelper = (body) => {
    navigation(body);
  };

  useMemo(() => {
    onFilterChange("contextContains", stepContextFilter);
  }, [stepContextFilter]);
  useMemo(() => {
    onFilterChange("requestId", requestIDFilter);
  }, [requestIDFilter]);
  useMemo(() => {
    onFilterChange("nameContains", stepNameFilter);
  }, [stepNameFilter]);
  useMemo(() => {
    onFilterChange("requestStatusIn", stepStatusFilter);
  }, [stepStatusFilter]);
  useMemo(() => {
    onFilterChange("limit", amountOfRows);
  }, [amountOfRows]);

  const searchFilters = async (updatedSearchBody = null) => {
    setLoading(true);
    setPage(1);
    try {
      await getSteps({
        token: null,
        dispatch: dispatch,
        prevSteps: stepsList,
        nextPage: 1,
        searchBody: updatedSearchBody ?? searchBody,
      });
    } finally {
      setLoading(false);
    }
  };

  const onSearchClick = async (e) => {
    e.preventDefault();
    await searchFilters();
  };

  const onNewAmountOfRowsSelected = async (newValue) => {
    setAmountOfRows(newValue);
    // Use the onFilterChange function to update the url params,
    // but the updating of the searchBody var should be done
    // manually to garantee it is updateded before making a
    // search-api-call directly inside this function
    onFilterChange("limit", newValue);

    // Create deepcopy of the currentSearchBody to avoid
    // collision as the previous onFilterChange function modifies
    // the searchBody as well
    let newSearchBody = JSON.parse(JSON.stringify(searchBody));

    delete newSearchBody["limit"];
    newSearchBody["limit"] = newValue;
    await searchFilters(newSearchBody);
  };

  return (
    <div>
      <div className={styles.screen_top__div}>
        <div className={styles.screen_header__col}>
          <div className={styles.title__section}>
            <Typography className={styles.title} variant="h5">
              Steps
            </Typography>
          </div>

          <div className={styles.subtitle__section}>
            <p className={styles.subtitle}>
              Each step is a unit of work that contains instructions to
              manipulate data for processing by software installed on the
              cluster.
            </p>
          </div>
        </div>

        <div className={styles.action_bar__row}>
          <Button
            variant="outlined"
            className={styles.action_bar__refresh_button}
            endIcon={<RefreshIcon />}
            onClick={(e) => onRefresh()}
            disabled={!canRefresh}
          >
            Refresh
          </Button>
          <Link
            to={`/steps/clone/${selectedStepID ?? ""}`}
            target="_bank"
            className="link__no_decoration"
            onClick={(e) => handleLinkClick(e, selectedStepID === null)}
          >
            <Button
              variant="outlined"
              className={
                selectedStepID === null
                  ? styles.action_bar__disabled_button
                  : styles.action_bar__clone_button
              }
              disabled={selectedStepID === null}
            >
              Clone Step
            </Button>
          </Link>
        </div>
      </div>

      <form onSubmit={onSearchClick}>
        <div className={styles.filter_bar}>
          <div className={styles.filter_bar__row}>
            <MultipleSelect
              selectLabelID="step-status-multi-select-label"
              selectLabel="Step Status"
              options={STEP_STATUS_LIST}
              onFilterChange={setStepStatusFilter}
              defaultValue={stepStatusFilter}
            />
          </div>

          <div className={styles.filter_bar__row}>
            <div className={styles.filter_bar__input__div}>
              <FormTextField
                value={stepNameFilter}
                setter={setStepNameFilter}
                placeholder="Step Name"
                size="small"
                className={styles.filter_bar__input}
              />
            </div>
          </div>

          <div className={styles.filter_bar__row}>
            <div className={styles.filter_bar__input__div}>
              <FormTextField
                value={requestIDFilter}
                setter={setRequestIDFilter}
                placeholder="Request ID"
                size="small"
                className={styles.filter_bar__input}
              />
            </div>
          </div>

          <div className={styles.filter_bar__row}>
            <div className={styles.filter_bar__input__div}>
              <FormTextField
                value={stepContextFilter}
                setter={setStepContextFilter}
                placeholder="Step Context"
                size="small"
                className={styles.filter_bar__input}
              />
            </div>
          </div>

          <div className={styles.filter_bar__button__div}>
            <Button
              variant="contained"
              className={styles.filter_bar__button}
              startIcon={<SearchIcon />}
              type="submit"
            >
              Filter
            </Button>
          </div>

          <div className={styles.action_bar__table_options_section}>
            <div className={styles.row_amount_selection__div}>
              <Typography className={styles.row_amount_selection__title}>
                Rows
              </Typography>
              <FormSelectField
                labelID="steps-screen-select-amount-of-rows"
                value={amountOfRows}
                onChange={(value) => {
                  onNewAmountOfRowsSelected(value);
                }}
                options={STEPS_ROWS_PER_PAGE_LIST}
                modalWidth="100px"
                size="small"
                height={35}
              />
            </div>

            <div className={styles.pagiantion__div}>
              <CustomPagination
                currentPage={page}
                onChangePageNumeber={onChangePageNumber}
                hasNext={hasNextPage}
                canDisplay={canDisplayPagination}
              />
            </div>
          </div>
        </div>
      </form>

      <div className={styles.table__div}>
        <DataGridPremium
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={(params) => (
            <TableDetailPanel {...params.row} />
          )}
          density={"comfortable"}
          rows={stepsToDisplay.length ? stepsToDisplay : stepsList}
          loading={loading}
          columns={columns}
          disableColumnFilter
          disableColumnSorting
          sortingMode={"server"}
          isRowSelectable={(params) =>
            !params.row.context.startsWith(HYDROS_PROD_CONTEXT)
          }
          rowCount={-1}
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#F9FAFA",
            },
            border: "none",
          }}
          onRowSelectionModelChange={(e) => handleCheckboxClick(e)}
          disableMultipleRowSelection={true}
          checkboxSelection={true}
          disableRowSelectionOnClick={true}
          initialState={{
            columns: {
              columnVisibilityModel: {
                startTime: false,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default StepsPage;
