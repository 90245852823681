import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import {
  gridFilterModelSelector,
  useGridSelector,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import { VALID_TEXTFIELD_BORDER_COLOR } from "../../../../utils/constants";
import { CLUSTER_STATE_LIST } from "../../../../utils/clusters-constants";

const getDefaultFilter = (field) => ({ field, operator: "contains" });

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function ClusterStateFilter(props) {
  const { colDef } = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = useMemo(
    () => filterModel.items?.filter(({ field }) => field === colDef.field),
    [colDef.field, filterModel.items]
  );

  const handleChange = useCallback(
    (event) => {
      if (!event.target.value) {
        if (currentFieldFilters[0]) {
          apiRef.current.deleteFilterItem(currentFieldFilters[0]);
        }
        return;
      }
      apiRef.current.upsertFilterItem({
        ...(currentFieldFilters[0] || getDefaultFilter(colDef.field)),
        value: event.target.value,
      });
    },
    [apiRef, colDef.field, currentFieldFilters]
  );

  const value =
    typeof currentFieldFilters[0]?.value === "string"
      ? currentFieldFilters[0]?.value.split(",")
      : currentFieldFilters[0]?.value ?? [];
  const label = "Filter";

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth>
      <InputLabel
        id="select-cluster-state-label"
        sx={{
          fontSize: 16,
          color: "#a3a3a3",
          height: 40,
          marginTop: -1.5,
          marginLeft: 1,
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="select-cluster-state-label"
        id="select-cluster-state"
        multiple
        input={<OutlinedInput label={"Filter"} />}
        renderValue={(selected) => selected.join(", ")}
        value={value}
        onChange={handleChange}
        MenuProps={MenuProps}
        sx={{
          height: 40,
          borderRadius: 0,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: VALID_TEXTFIELD_BORDER_COLOR,
            borderRadius: "0px",
          },
        }}
      >
        {CLUSTER_STATE_LIST.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
