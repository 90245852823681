import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateMenuLink } from "../../redux/slices/menu-slice";
import styles from "./AddStep.module.css";
import { Typography, Button } from "@mui/material";
import FormTextField from "../../components/FormFields/FormTextField/FormTextField";
import "../../global.css";
import CreateStep from "../../components/CreateStep/CreateStep";

const AddStepPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateMenuLink({ linkName: "Add Step" }));
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.title__section}>
        <Typography className={styles.title} variant="h5">
          Add Step
        </Typography>
      </div>

      <div className={styles.add_step__section}>
        <CreateStep />
      </div>
    </div>
  );
};

export default AddStepPage;
