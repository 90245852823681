import { getUrl } from "../utils/shared-functions";
import { getAWSClient } from "./AuthService";

export const getNativeEndpoints = async (
  clusterId,
  setNativeEndpoints,
  stepId
) => {
  try {
    const aws = await getAWSClient();
    const domain = await getUrl();

    const url = `${domain}/EmrAppsEndPoint`;

    const requestBody = {
      clusterId: clusterId,
      stepId: stepId,
    };

    const res = await aws.fetch(url, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });

    if (res.status === 200) {
      const result = await res.json();

      const sparkHistoryUri = result.endpoints.filter(
        (endpoint) => endpoint.appName === "spark"
      );
      const sparkResourceUri = result.endpoints.filter(
        (endpoint) => endpoint.appName === "resource"
      );
      const sparkHdfsUri = result.endpoints.filter(
        (endpoint) => endpoint.appName === "hdfs"
      );
      const sparkJobUri = result.endpoints.filter(
        (endpoint) => endpoint.appName === "sparkJob"
      );
      // sample backend response
      /**
       * {
        "endpoints": [
            {
            "appEndPoint": "https://emrapps.hydros.fpa.amazon.dev/?app=spark&cluster=j-329MGYFXDP86P",
            "appName": "spark"
            },
            {
            "appEndPoint": "https://emrapps.hydros.fpa.amazon.dev/?app=resource&cluster=j-329MGYFXDP86P",
            "appName": "resource"
            },
            {
            "appEndPoint": "https://emrapps.hydros.fpa.amazon.dev/?app=hdfs&cluster=j-329MGYFXDP86P",
            "appName": "hdfs"
            },
            {
            "appEndPoint": "https://emrapps.hydros.fpa.amazon.dev/?app=spark&cluster=j-329MGYFXDP86P&applicationId=application_1724964891865_0169",
            "appName": "sparkJob"
            }
        ]
        }
       */

      setNativeEndpoints({
        spark: sparkHistoryUri[0].appEndPoint,
        resource: sparkResourceUri[0].appEndPoint,
        hdfs: sparkHdfsUri[0].appEndPoint,
        sparkJob: sparkJobUri.length > 0 ? sparkJobUri[0].appEndPoint : "",
      });
    } else {
      console.error("Error fetching EMR native endpoints:", res.statusText);
    }
  } catch (error) {
    console.error("Error fetching EMR native endpoints:", error);
  }
};
