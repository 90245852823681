import { useParams } from "react-router-dom";
import { getStepLogs } from "../../services/StepsService";
import { useEffect, useState } from "react";
import styles from "./StepsLog.module.css";
import { useDispatch } from "react-redux";
import { updateMenuLink } from "../../redux/slices/menu-slice";

const StepLogPage = () => {
  const [logs, setLogs] = useState(null);

  const { stepID, logType } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateMenuLink({ linkName: "Step Logs" }));

    // Hide the navbar and remove top margins
    const navbar = document.getElementById("navbar");
    const appBody = document.getElementById("app-body");
    if (navbar && appBody) {
      navbar.style.display = "none";
      appBody.style.marginTop = "0px";
    }

    // Fetch the step logs
    async function getStepLogsHelper() {
      await getStepLogs(stepID, logType, setLogs);
    }

    if (stepID && logType) {
      getStepLogsHelper();
    }
  }, []);

  return (
    <div className={styles.step_logs__div}>
      <div className={styles.step_logs__body}>
        <pre className={styles.step_logs}>{logs}</pre>
      </div>
    </div>
  );
};

export default StepLogPage;
