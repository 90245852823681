const CHIP_SUCCESS_COLOR_MAP = {
  color: "#1F741F",
  backgroundColor: "#EBFAEB",
  outlineColor: "#2e7d32",
  outlineBackgroundColor: "white",
  outlinedBorderColor: "#2e7d32",
};

const CHIP_IN_PROCESSS_COLOR_MAP = {
  color: "#B36B00",
  backgroundColor: "#FFF5E4",
  outlineColor: "#ff9800",
  outlineBackgroundColor: "white",
  outlinedBorderColor: "#ff9800",
};

const CHIP_FAILURE_COLOR_MAP = {
  color: "#800000",
  backgroundColor: "#FFE6E6",
  outlineColor: "#d32f2f",
  outlineBackgroundColor: "white",
  outlinedBorderColor: "#d32f2f",
};

const CHIP_COMPLETION_COLOR_MAP = {
  color: "#a3a3a3",
  backgroundColor: "#dbdbdb",
  outlineColor: "#a3a3a3",
  outlineBackgroundColor: "white",
  outlinedBorderColor: "#a3a3a3",
};

export const CLUSTER_STATUS_LIST = ["LIVE", "DEAD"];

export const CLUSTER_STATE_LIST = [
  "STARTING",
  "BOOTSTRAPPING",
  "RUNNING",
  "WAITING",
  "TERMINATING",
  "TERMINATED",
  "TERMINATED_WITH_ERRORS",
];

export const CLUSTER_CHIP_COLOR_MAP = {
  LIVE: CHIP_SUCCESS_COLOR_MAP,
  DEAD: CHIP_FAILURE_COLOR_MAP,
  STARTING: CHIP_IN_PROCESSS_COLOR_MAP,
  BOOTSTRAPPING: CHIP_IN_PROCESSS_COLOR_MAP,
  RUNNING: CHIP_IN_PROCESSS_COLOR_MAP,
  WAITING: CHIP_IN_PROCESSS_COLOR_MAP,
  TERMINATING: CHIP_IN_PROCESSS_COLOR_MAP,
  TERMINATED: CHIP_COMPLETION_COLOR_MAP,
  TERMINATED_WITH_ERRORS: CHIP_FAILURE_COLOR_MAP,
};

export const DEFAULT_CLUSTER_STATE = "RUNNING";
export const DEFAULT_CLUSTER_STATUS = "LIVE";

export const CLUSTER_LIVE_STATES = [
  "STARTING",
  "BOOTSTRAPPING",
  "RUNNING",
  "WAITING",
  "TERMINATING",
];
export const CLUSTER_DEAD_STATES = ["TERMINATED", "TERMINATED_WITH_ERRORS"];
