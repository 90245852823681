import { getUrl } from "../utils/shared-functions";
import { getAWSClient } from "./AuthService";
import { updateClustersList } from "../redux/slices/clusters-slice";
import { fixMissingClusterForPagination } from "../utils/clusters-service-utils";

export const getClusters = async ({
  token = null,
  dispatch,
  prevClusters,
  nextPage,
  searchBody = {},
}) => {
  try {
    const aws = await getAWSClient();
    const domain = await getUrl();

    const newLimit = parseInt(searchBody["limit"] ?? "10");
    searchBody["limit"] = newLimit;

    searchBody["nextToken"] = token;
    const requestBody = JSON.stringify(searchBody);

    let url = `${domain}/QueryClusters`;
    const res = await aws.fetch(url, {
      method: "POST",
      body: requestBody,
    });
    res.json().then((result) => {
      const clustersFixed = fixMissingClusterForPagination(
        result.clusters ?? [],
        newLimit
      );
      if (clustersFixed.length) {
        dispatch(
          updateClustersList({
            clustersList: token
              ? prevClusters.concat(clustersFixed)
              : clustersFixed,
            nextToken: result.nextToken ?? null,
            lastPageFetch: nextPage,
          })
        );
      } else {
        dispatch(
          updateClustersList({
            clustersList: token ? prevClusters : [],
            nextToken: null,
            lastPageFetch: nextPage - 1,
          })
        );
      }
    });
  } catch (e) {
    console.log("getClusters failed", e);
    dispatch(
      updateClustersList({
        clustersList: [],
        nextToken: null,
        lastPageFetch: 0,
      })
    );
  }
};
