import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import styles from "./MultiSelect.module.css";
import { VALID_TEXTFIELD_BORDER_COLOR } from "../../utils/constants";
import { useEffect } from "react";

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 292,
    },
  },
};

const MultipleSelect = ({
  selectLabelID,
  selectLabel,
  options,
  onFilterChange,
  defaultValue = [],
}) => {
  const [optionName, setOptionName] = React.useState([]);

  useEffect(() => {
    setOptionName(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setOptionName(typeof value === "string" ? value.split(",") : value);

    onFilterChange(value);
  };

  return (
    <div>
      <FormControl
        sx={{
          m: 1,
          paddingTop: 0.8,
          marginTop: 1,
        }}
      >
        <InputLabel
          id={selectLabelID}
          sx={{
            fontSize: 16,
            color: "#a3a3a3",
            height: 40,
          }}
        >
          {selectLabel}
        </InputLabel>
        <Select
          labelId={selectLabelID}
          multiple
          value={optionName}
          onChange={handleChange}
          input={<OutlinedInput label={selectLabel} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          sx={{
            width: 292,
            height: 40,
            borderRadius: 0,
            bgcolor: "white",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: VALID_TEXTFIELD_BORDER_COLOR,
              borderRadius: "0px",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={optionName.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelect;
