const DEFAULT_CLUSTER = (index) => {
  return { id: `non-existing-cluster-row-${index}` };
};

export const fixMissingClusterForPagination = (clusters, amount) => {
  if (clusters.length && clusters.length < amount - 1) {
    const oldLength = clusters.length;
    for (let i = 0; i < amount - oldLength; i++) {
      clusters.push(DEFAULT_CLUSTER(i));
    }
  }
  return clusters;
};
