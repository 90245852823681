// React libraries imports
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Local components imports
import styles from "./Navbar.module.css";
import GFTLogo from "../../assets/logos/Logo.svg";

// Redux and logic imports
import { useSelector } from "react-redux";

// Material UI imports
import { AppBar, Toolbar, Typography, Grid, Button } from "@mui/material";
import { LINKS_MAP } from "../../utils/constants";

const Navbar = ({ username = "", handleOpenFeedbackModal }) => {
  const menuLink = useSelector((state) => state.menu.linkName);

  const [selectedLink, setSelectedLink] = useState("");

  useEffect(() => {
    setSelectedLink(menuLink);
  }, [menuLink]);

  return (
    <div id="navbar">
      <AppBar className={styles.navbar}>
        <Toolbar>
          <Grid container>
            <Grid item xs={4}>
              <div className={styles.navbar__brand}>
                <div className={styles.navbar__brand__logo}>
                  <img
                    className={styles.navbar__brand__logo__div}
                    src={GFTLogo}
                  />
                </div>
                <Typography variant="h5">
                  <Link to="/" className={styles.navbar__link}>
                    EMR Service
                  </Link>
                </Typography>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className={styles.navbar__links_div}>
                {LINKS_MAP.map((link, linkIndex) => {
                  return (
                    <Link
                      key={linkIndex}
                      to={link.linkTo}
                      className={
                        selectedLink === link.name
                          ? styles.navbar__link__selected
                          : styles.navbar__link
                      }
                    >
                      {link.name}
                    </Link>
                  );
                })}
              </div>
            </Grid>

            <Grid item xs={4} className={styles.navbar__user_section}>
              <Grid
                container
                direction={"row"}
                className={styles.navbar__user_section__row}
              >
                <Grid item className={styles.feedback_button__grid}>
                  <div className={styles.feedback_button__div}>
                    <Button
                      className={styles.feedback_button}
                      onClick={(e) => handleOpenFeedbackModal()}
                    >
                      Feedback
                    </Button>
                  </div>
                </Grid>

                <Grid item className={styles.navbar__account__grid}>
                  <div className={styles.navbar__account__div}>
                    <div className={styles.navbar__account_name}>
                      Welcome, {username}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
