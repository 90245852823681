import ClusterStateFilter from "./ClusterStateFilter";
import ClusterStatusFilter from "./ClusterStatusFilter";

export const clusterContainsStateArrayOperator = [
  {
    label: "Contains",
    value: "contains",
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value)) {
        return null;
      }
      return ({ value }) => {
        return value != null;
      };
    },
    InputComponent: ClusterStateFilter,
  },
];

export const clusterContainsStatusArrayOperator = [
  {
    label: "Contains",
    value: "contains",
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value)) {
        return null;
      }
      return ({ value }) => {
        return value != null;
      };
    },
    InputComponent: ClusterStatusFilter,
  },
];
