import LinkTooltip from "../../components/LinkTooltip/LinkTooltip";
import { getFormattedElapsedTimeFromStep } from "../../utils/shared-functions";
import styles from "../../components/Tables/StepsTable/StepsTable.module.css";
import { Chip } from "@mui/material";
import { CHIP_COLOR_MAP, DEFAULT_STEP_STATUS } from "../../utils/constants";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import React from "react";

export const columns = [
  {
    field: "id",
    headerName: "ID",
    sortable: false,
    flex: 1,
    maxWidth: 60,
    renderCell: (row) => (
      <LinkTooltip displayText={row.id.slice(-4)} copyText={row.id} />
    ),
  },
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (row) => (
      <LinkTooltip displayText={row.value} copyText={row.value} />
    ),
  },
  {
    field: "context",
    headerName: "Context",
    sortable: false,
    flex: 1,
    minWidth: 150,
    renderCell: (row) => (
      <LinkTooltip displayText={row.value} copyText={row.value} />
    ),
  },
  {
    field: "elapsedTime",
    headerName: "Duration",
    sortable: false,
    flex: 1,
    maxWidth: 80,
    renderCell: ({ row }) => {
      return <span>{getFormattedElapsedTimeFromStep(row)}</span>;
    },
  },
  {
    field: "requestStatus",
    headerName: "Status",
    sortable: false,
    flex: 1,
    minWidth: 50,
    renderCell: ({ row }) => {
      return (
        <div className={styles.table_body_cell__div}>
          {row.requestStatus ? (
            <Chip
              data-testid={`steps-table-row-${row.id}-status-chip`}
              label={row.requestStatus}
              size="small"
              sx={{
                backgroundColor:
                  CHIP_COLOR_MAP[row.requestStatus ?? DEFAULT_STEP_STATUS]
                    .backgroundColor,
                color:
                  CHIP_COLOR_MAP[row.requestStatus ?? DEFAULT_STEP_STATUS]
                    .color,
                width: "100px",
                borderRadius: "0px",
              }}
            />
          ) : (
            <></>
          )}
        </div>
      );
    },
  },
  {
    field: "logs",
    headerName: "Logs",
    sortable: false,
    flex: 1,
    minWidth: 250,
    renderCell: ({ id }) => {
      return (
        <div className={styles.table_body_cell__div}>
          <Link
            to={`/steps/logs/${id}/controller`}
            target="_bank"
            className="link__no_decoration"
          >
            <span className={`link--color`}>controller</span>
          </Link>{" "}
          <Link
            to={`/steps/logs/${id}/stderr`}
            target="_bank"
            className="link__no_decoration"
          >
            <span className={`link--color`}>| stderr</span>
          </Link>{" "}
          <Link
            to={`/steps/logs/${id}/stdout`}
            target="_bank"
            className="link__no_decoration"
          >
            <span className={`link--color`}>| stdout</span>
          </Link>
        </div>
      );
    },
  },
  {
    // Desired date format Jun 6, 2024, 05:15:12
    field: "submitTime",
    headerName: "Submit Time",
    sortable: false,
    flex: 1,
    valueFormatter: (params) =>
      moment(params.value).format("MMM D, YYYY, HH:mm:ss"),
  },
  {
    field: "startTime",
    headerName: "Start Time",
    sortable: false,
    flex: 1,
    valueFormatter: (params) =>
      moment(params.value).format("MMM D, YYYY, HH:mm:ss"),
  },
  {
    field: "endTime",
    headerName: "End Time",
    sortable: false,
    flex: 1,
    valueFormatter: (params) =>
      moment(params.value).format("MMM D, YYYY, HH:mm:ss"),
  },
];
