import React, { useEffect, useState } from "react";
import { Box, Button, List, ListItem, Typography, Chip } from "@mui/material";
import LinkTooltip from "../../../LinkTooltip/LinkTooltip";
import { getStep } from "../../../../services/StepsService";
import { getFormattedDistributionDelayTimeFromStep } from "../../../../utils/shared-functions";
import { getNativeEndpoints } from "../../../../services/NativeAppService";
import "./TableDetailPanel.css";

const TableDetailPanel = (row) => {
  const [stepInfo, setStepInfo] = useState({});
  const [isPretty, setIsPretty] = useState(true);
  const [nativeEndpoints, setNativeEndpoints] = useState({});

  const togglePretty = () => {
    setIsPretty(!isPretty);
  };

  useEffect(() => {
    getStep(row.id, setStepInfo);
  }, [row.id]);

  useEffect(() => {
    const res = getNativeEndpoints(
      row.emrClusterId,
      setNativeEndpoints,
      row.emrStepId
    );
    console.log(row.emrClusterId);
  }, [row.emrClusterId]);

  const parseArgs = (args) => {
    const result = [];
    let currentKey = null;

    args.forEach((arg) => {
      // if current is parsed, ALWAYS send the next entry as value
      if (currentKey) {
        result.push({ key: currentKey, value: arg });
        currentKey = null;
        // parse entry that has both key and value together
      } else if (arg.includes("=")) {
        const [key, value] = arg.split("=");
        result.push({ key, value });
        currentKey = null;
        // parse entry that only contains key
      } else if (arg.startsWith("--")) {
        if (currentKey) {
          result.push({ key: currentKey, value: null });
        }
        currentKey = arg;
      } else {
        result.push({ key: arg, value: null });
      }
    });

    if (currentKey) {
      result.push({ key: currentKey, value: null });
    }

    return result;
  };

  const formatJSON = (args) => {
    const parsedArgs = parseArgs(args);

    return (
      <List>
        {parsedArgs.map((arg, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                <strong>{arg.key}</strong> {arg.value}
              </Typography>
            </ListItem>
            {index < parsedArgs.length - 1}
          </React.Fragment>
        ))}
      </List>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", px: "2%", pt: "2%" }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography sx={{ fontSize: 14, mr: 2, alignContent: "center" }}>
          EMR Cluster ID:{" "}
          <LinkTooltip
            copyText={row.emrClusterId}
            displayText={row.emrClusterId}
          />
        </Typography>

        <Typography sx={{ fontSize: 14, mr: 2 }}>
          EMR Step ID:{" "}
          <LinkTooltip copyText={row.emrStepId} displayText={row.emrStepId} />
        </Typography>

        <Typography sx={{ fontSize: 14, mr: 2 }}>
          Distribution Delay: {getFormattedDistributionDelayTimeFromStep(row)}
        </Typography>

        <Box>
          <Chip
            label="History Server"
            component="a"
            color="primary"
            target="_blank"
            href={nativeEndpoints.spark}
            clickable
          />
          <Chip
            label="Resource Server"
            component="a"
            color="default"
            target="_blank"
            className="outline_btn"
            href={nativeEndpoints.resource}
            clickable
          />
          <Chip
            label="HDFS Server"
            component="a"
            color="default"
            target="_blank"
            className="outline_btn"
            href={nativeEndpoints.hdfs}
            clickable
          />
          {nativeEndpoints.sparkJob && (
            <Chip
              label="SparkJob"
              component="a"
              color="default"
              target="_blank"
              className="outline_btn"
              href={nativeEndpoints.sparkJob}
              clickable
            />
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <Button onClick={togglePretty} sx={{ mr: 2 }}>
          {isPretty ? "Show Raw" : "Prettify"}
        </Button>
      </Box>
      <Box sx={{ mt: 2, whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
        {isPretty ? (
          formatJSON(stepInfo.Step?.HadoopJarStep?.Args || [])
        ) : (
          <Typography sx={{ fontSize: 14 }}>
            {JSON.stringify(stepInfo, null, 2)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TableDetailPanel;
