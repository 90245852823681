// 3rd Party-Libraries import
import { TextField } from "@mui/material";

// Local import
import styles from "./FormTextField.module.css";
import {
  TEXTAREA_CHARACTER_LIMIT,
  VALID_TEXTFIELD_BORDER_COLOR,
} from "../../../utils/constants";

const FormTextField = ({
  label = "",
  placeholder = "",
  multiline = false,
  rows = 1,
  value = "",
  setter = () => {},
  hasCharactersLimit = false,
  hasResize = false,
  size = "medium",
  charactersLimit = null,
  errorText = "",
  className = "",
}) => {
  return (
    <TextField
      label={label}
      className={`${styles.form_text_field} ${className ?? ""}`}
      size={size}
      sx={{
        "& .MuiInputLabel-root": {
          color: "#757575",
          fontFamily: "Amazon Ember",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
        },
        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            borderColor: VALID_TEXTFIELD_BORDER_COLOR,
            borderRadius: "0px",
          },
        },
        "& .MuiOutlinedInput-root.Mui-disabled": {
          "& > fieldset": {
            borderColor: VALID_TEXTFIELD_BORDER_COLOR,
            borderRadius: "0px",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: VALID_TEXTFIELD_BORDER_COLOR,
        },
      }}
      inputProps={{
        maxLength: hasCharactersLimit
          ? charactersLimit ?? TEXTAREA_CHARACTER_LIMIT
          : null,
        style: hasResize ? { resize: "both" } : {},
      }}
      multiline={multiline}
      rows={rows}
      value={value}
      onChange={(e) => setter(e.target.value)}
      helperText={
        (hasCharactersLimit
          ? `${value.length}/${charactersLimit ?? TEXTAREA_CHARACTER_LIMIT}`
          : "") + (errorText.length ? " " + errorText : "")
      }
      errorText={errorText}
      error={errorText.length}
      placeholder={placeholder ?? ""}
    />
  );
};

export default FormTextField;
