const fetchSettings = async () => {
  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/settings.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const settingsJson = await response.json();
    return settingsJson;
  } catch (e) {
    console.log("There was a problem retrieving settings from backend", e);
  }
};

export const handleCopy = (text) => {
  navigator.clipboard.writeText(text);
};

export const getUrl = async () => {
  try {
    const settings = await fetchSettings();
    return settings["APIEndPoint"];
  } catch (e) {
    console.log("GetUrl failed");
  }
};

// Convert arguments string into an
// array of strings for EMR Service
export const formatArguments = (argString) => {
  if (!argString) {
    return [];
  }

  const splitCommands = argString.match(/(".*?"|[^"\s]+)(?=\s*|\s*$)/g);

  if (!splitCommands) {
    return [];
  }

  try {
    const doubleQuotePattern = /\\"/g;
    const quotePattern = /"/g;

    return splitCommands.map((item) =>
      item.replace(doubleQuotePattern, '"').replace(quotePattern, "")
    );
  } catch (error) {
    console.log(`Parsing argument error: ${error}`);
    return [];
  }
};

export const formatArgumentsToString = (argsArray) => {
  let argsString = argsArray[0];
  for (let i = 1; i < argsArray.length; i++) {
    argsString += ' "' + argsArray[i] + '"';
  }
  return argsString;
};

export const formatUsername = (username) => {
  return username.split(/_(.*)/s)[1];
};

export const formatMillisecondsToDateString = (millisecondsTime) => {
  const date = new Date(millisecondsTime);
  const formattedDate = date.toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    hour12: false,
    minute: "numeric",
    second: "numeric",
  });
  if (formattedDate === "Invalid Date") {
    return "";
  }
  return formattedDate;
};

export const getFormattedElapsedTimeFromStep = (step) => {
  if (!step.startTime) {
    return "";
  }

  const elapsedTimeInMillisecond =
    step.elapsedTime || Date.now() - step.startTime;
  const totalSeconds = Math.floor(elapsedTimeInMillisecond / 1000);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedTime = [];

  if (hours > 0) {
    formattedTime.push(`${hours}h`);
  }
  if (minutes > 0) {
    formattedTime.push(`${minutes}m`);
  }
  if (seconds > 0) {
    formattedTime.push(`${seconds}s`);
  }

  return formattedTime.length > 0
    ? formattedTime.join(" ")
    : "Less than 1 second";
};

export const decodeURLParams = (params, FILTERS_MAP) => {
  const decodedParams = {};
  if (params && Array.from(params).length) {
    params.forEach((value, key) => {
      // Check if the filter expects an array|null
      // or a string as value
      if (FILTERS_MAP[key].type === "array") {
        const filterArray = params.getAll(key);
        decodedParams[key] = filterArray;
      } else {
        decodedParams[key] = params.get(key);
      }
    });
  }
  return decodedParams;
};

export const getFormattedDistributionDelayTimeFromStep = (step) => {
  if (!step.submitTime || !step.startTime) {
    return "";
  }

  const elapsedTimeInMillisecond = step.startTime - step.submitTime;
  const totalSeconds = Math.floor(elapsedTimeInMillisecond / 1000);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedTime = [];

  if (hours > 0) {
    formattedTime.push(`${hours}h`);
  }
  if (minutes > 0) {
    formattedTime.push(`${minutes}m`);
  }
  if (seconds > 0) {
    formattedTime.push(`${seconds}s`);
  }

  return formattedTime.length > 0
    ? formattedTime.join(" ")
    : "Less than 1 second";
};
