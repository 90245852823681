import { useEffect, useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import styles from "./FeedbackModal.module.css";
import FormTextField from "../../FormFields/FormTextField/FormTextField";
import FormSelectField from "../../FormFields/FormSelectField/FormSelectField";
import FormRadioGroup from "../../FormFields/FormRadioGroup/FormRadioGroup";
import { detectBrowser } from "../../../utils/browser-detection-utils";
import { createSimTicket } from "../../../services/SimTicketService";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const FEEDBACK_TYPE_LIST = [
  "Feedback and feature request",
  "Feedback and visual enhancement request",
  "Bug report",
];

const RATING_EXPERIENCE = [
  "Very satisfied",
  "Somehow satisfied",
  "Not very satisfied",
  "Not satisfied at all",
];

const RATING_PRIORITY = [
  "Critical",
  "Very important",
  "Somehow important",
  "Nice to have",
];

const FeedbackModal = ({ isOpen, handleClose, username = "", addMessage }) => {
  const [feedbackType, setFeedbackType] = useState(FEEDBACK_TYPE_LIST[0]);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [rateExperience, setRateExperience] = useState(RATING_EXPERIENCE[0]);
  const [ratePriority, setRatePriority] = useState(RATING_PRIORITY[0]);
  const [currentUsername, setCurrentUsername] = useState("username");
  const [currentBrowser, setCurrentBrowser] = useState("");
  const [currentScreenResolution, setCurrentScreenResolution] =
    useState("0 x 0");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseHelper = () => {
    setFeedbackType(FEEDBACK_TYPE_LIST[0]);
    setFeedbackMessage("");
    setRateExperience(RATING_EXPERIENCE[0]);
    setRatePriority(RATING_PRIORITY[0]);
    setIsLoading(false);
    handleClose();
  };

  useEffect(() => {
    setCurrentUsername(username);
  }, [username]);

  useEffect(() => {
    const browser = detectBrowser();
    setCurrentBrowser(browser);
    setCurrentScreenResolution(
      `${window.innerWidth.toString()} x ${window.innerHeight.toString()}`
    );
  }, []);

  const onSubmitHelper = async () => {
    setIsLoading(true);
    const ticketContent = {
      title: `EMR Service - ${feedbackType}`,
      description: `**Type:** ${feedbackType} \n\n**Priority:** ${ratePriority}\n\n**Rate user experience:** ${rateExperience}\n\n**User:** ${currentUsername}\n\n**Message:** ${feedbackMessage}\n\n**Browser:** ${currentBrowser}\n\n**Screen resolution (w x h):** ${currentScreenResolution}`,
    };

    if (feedbackMessage.length) {
      await createSimTicket(ticketContent, addMessage);
      handleCloseHelper();
    } else {
      addMessage({
        type: "error",
        messageBody: [
          "An error occurred while creating the ticket. Please fill all the fields",
        ],
      });
      setIsLoading(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={handleCloseHelper}>
      <Box className={styles.modal__box} sx={modalStyle}>
        <Typography className={styles.modal__title} variant="h6" component="h2">
          Feedback for EMR Service UI
        </Typography>

        <div className={styles.modal__body}>
          <div className={styles.modal__form_field}>
            <FormSelectField
              label="Feedback type"
              labelID="select-feedback-modal-feedback-type"
              value={feedbackType}
              onChange={setFeedbackType}
              options={FEEDBACK_TYPE_LIST}
              modalWidth="730px"
            />
          </div>

          <div className={styles.modal__form_field}>
            <FormRadioGroup
              label="What priority would you give to your request?"
              labelID="feedback-modal-rate-priority"
              value={ratePriority}
              onChange={setRatePriority}
              options={RATING_PRIORITY}
            />
          </div>

          <div className={styles.modal__form_field}>
            <FormTextField
              label="Enter your message below"
              value={feedbackMessage}
              setter={setFeedbackMessage}
              multiline={true}
              rows={5}
              hasResize={true}
              size="small"
            />
          </div>

          <div className={styles.modal__form_field}>
            <FormRadioGroup
              label="How satisfied are you with your experience at EMR Service?"
              labelID="feedback-modal-rate-experience"
              value={rateExperience}
              onChange={setRateExperience}
              options={RATING_EXPERIENCE}
            />
          </div>

          <div className={styles.modal__info_collected__div}>
            <Typography className={styles.modal__info_collected__title}>
              What other information are we collecting?
            </Typography>

            <div className={styles.modal__info__collected__item__section}>
              <div className={styles.modal__info__collected__item}>
                <Typography
                  className={styles.modal__info__collected__item__title}
                >
                  Username:
                </Typography>{" "}
                <Typography
                  className={styles.modal__info__collected__item__value}
                >
                  {currentUsername}
                </Typography>
              </div>

              <div className={styles.modal__info__collected__item}>
                <Typography
                  className={styles.modal__info__collected__item__title}
                >
                  Browser:
                </Typography>{" "}
                <Typography
                  className={styles.modal__info__collected__item__value}
                >
                  {currentBrowser}
                </Typography>
              </div>

              <div className={styles.modal__info__collected__item}>
                <Typography
                  className={styles.modal__info__collected__item__title}
                >
                  Screen size for browser:
                </Typography>{" "}
                <Typography
                  className={styles.modal__info__collected__item__value}
                >
                  {currentScreenResolution}
                </Typography>
              </div>
            </div>
          </div>

          <div className={styles.modal__buttons_row}>
            <div className={styles.modal__cancel_button__div}>
              <Button
                className={styles.modal__cancel_button}
                onClick={handleCloseHelper}
              >
                Cancel
              </Button>
            </div>

            <div className={styles.modal__submit_button__div}>
              <Button
                className={styles.modal__submit_button}
                onClick={onSubmitHelper}
                disabled={isLoading}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
