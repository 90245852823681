import { configureStore } from "@reduxjs/toolkit";
// import userReducer from "./user-slice";
import menuReducer from "./slices/menu-slice";
import userReducer from "./slices/user-slice";
import stepsReducer from "./slices/steps-sclice";
import clustersReducer from "./slices/clusters-slice";

export const store = configureStore({
  reducer: {
    menu: menuReducer,
    user: userReducer,
    steps: stepsReducer,
    clusters: clustersReducer,
  },
});
