import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../../services/AuthService";
import "../../global.css";
import styles from "./Layout.module.css";
import Navbar from "../Navbar/Navbar";
import { useDispatch } from "react-redux";
import { updateUsername } from "../../redux/slices/user-slice";
import { formatUsername } from "../../utils/shared-functions";
import { Button } from "@mui/material";
import FeedbackModal from "../Modals/Feedback Modal/FeedbackModal";
import AlertStack from "../AlertStack/AlertStack";

const Layout = ({ children }) => {
  // Local Variables
  const [user, setUser] = useState(null);
  const [selectedLink, setSelectedLink] = useState("");
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const dispatch = useDispatch();

  const [messages, setMessage] = useState([]);

  const handleAddMessage = (newMessage) => {
    setMessage((messages) => [...messages, newMessage]);
  };

  const handleDeleteMessage = (messageIndex) => {
    setMessage(messages.filter((message, index) => index !== messageIndex));
  };

  useEffect(() => {
    getCurrentUser()
      .then((user) => {
        const newUsername = formatUsername(user.username ?? "");
        setUser(newUsername);
      })
      .catch((e) => console.log("Failed getting current user"));
  }, []);

  useEffect(() => {
    dispatch(updateUsername({ username: user }));
  }, [user]);

  const handleCloseFeedbackModal = () => {
    setIsFeedbackModalOpen(false);
  };

  const handleFeedbackModalOpen = () => {
    setIsFeedbackModalOpen(true);
  };

  return user ? (
    <div className={styles.main}>
      <div className={styles.navbar__div}>
        <Navbar
          username={user}
          handleOpenFeedbackModal={handleFeedbackModalOpen}
        />
      </div>
      <div id="app-body" className={styles.body}>
        {children}

        <AlertStack messages={messages} deleteMessage={handleDeleteMessage} />

        <FeedbackModal
          isOpen={isFeedbackModalOpen}
          handleClose={handleCloseFeedbackModal}
          username={user}
          addMessage={handleAddMessage}
        />
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default Layout;
