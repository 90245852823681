import { Grid, Pagination, PaginationItem, Typography } from "@mui/material";
import "../../global.css";

const CustomPagination = ({
  currentPage,
  onChangePageNumeber,
  hasNext,
  canDisplay,
}) => {
  const canRenderItem = (itemType) => {
    return (itemType === "next" && hasNext) || itemType === "previous";
  };

  const isDisabled = (itemType) => {
    if (itemType === "previous" && currentPage === 1) return true;
    return !canDisplay;
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="baseline"
    >
      <Typography variant="body2">
        <span className="span--bold"> Page {currentPage}</span>
      </Typography>

      <Pagination
        count="infinite"
        size={"large"}
        page={currentPage}
        onChange={onChangePageNumeber}
        renderItem={(item) =>
          canRenderItem(item.type) && (
            <PaginationItem {...item} disabled={isDisabled(item.type)} />
          )
        }
      />
    </Grid>
  );
};

export default CustomPagination;
