// React Libreries
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Local components imports
import StepsPage from "./pages/Steps/Steps";
import ClustersPage from "./pages/Clusters/Clusters";
import AddStepPage from "./pages/AddStep/AddStep";
import Layout from "./components/Layouts/Layout";
import reportWebVitals from "./reportWebVitals";
import StepLogPage from "./pages/StepLog/StepLog";
import CloneStepPage from "./pages/CloneStep/CloneStep";

// Redux imports
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "21df911451adf7f6504fb4ac86f44ccbTz03OTgwOSxFPTE3MzMwNjU5MjQwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<StepsPage />} />
          <Route exact path="/clusters" element={<ClustersPage />} />
          <Route exact path="/add-step" element={<AddStepPage />} />
          <Route
            exact
            path="/steps/logs/:stepID/:logType"
            element={<StepLogPage />}
          />
          <Route
            exact
            path="/steps/clone/:stepID"
            element={<CloneStepPage />}
          />
        </Routes>
      </Layout>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
