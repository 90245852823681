import { Chip } from "@mui/material";
import React from "react";
import {
  CLUSTER_CHIP_COLOR_MAP,
  DEFAULT_CLUSTER_STATE,
  DEFAULT_CLUSTER_STATUS,
} from "../../../../../utils/clusters-constants";

export default function ClusterStateCellRender(props) {
  const { hasFocus, value } = props;
  return value ? (
    <Chip
      label={value}
      size="small"
      sx={{
        backgroundColor:
          CLUSTER_CHIP_COLOR_MAP[value ?? DEFAULT_CLUSTER_STATE]
            .outlineBackgroundColor,
        color:
          CLUSTER_CHIP_COLOR_MAP[value ?? DEFAULT_CLUSTER_STATE].outlineColor,
        border: `1px solid ${
          CLUSTER_CHIP_COLOR_MAP[value ?? DEFAULT_CLUSTER_STATE]
            .outlinedBorderColor
        }`,
      }}
    />
  ) : (
    <></>
  );
}
