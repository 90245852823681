import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMenuLink } from "../../redux/slices/menu-slice";
import { DataGridPremium, useGridApiContext } from "@mui/x-data-grid-premium";
import { getClusters } from "../../services/ClustersService";
import styles from "./Clusters.module.css";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  decodeURLParams,
  formatMillisecondsToDateString,
} from "../../utils/shared-functions";
import ClusterStatusCellRender from "../../components/Tables/ClustersTable/ClusterTableCells/ClusterStatusCellRender/ClusterStatusCellRender";
import ClusterStateCellRender from "../../components/Tables/ClustersTable/ClusterTableCells/ClusterStateCellRender/ClusterStateCellRender";
import usePagination from "../../hooks/usePagination/usePagination";
import CustomPagination from "../../components/Pagination/CustomPagination";
import ClusterIDCellRender from "../../components/Tables/ClustersTable/ClusterTableCells/ClusterIDCellRender/ClusterIDCellRender";
import { CLUSTERS_ROWS_PER_PAGE_LIST } from "../../utils/constants";
import FormSelectField from "../../components/FormFields/FormSelectField/FormSelectField";
import { useLocation, useNavigate } from "react-router-dom";
import ClusterStateFilter from "../../components/Tables/ClustersTable/ClusterTableFilters/ClusterStateFilter";
import {
  clusterContainsStateArrayOperator,
  clusterContainsStatusArrayOperator,
} from "../../components/Tables/ClustersTable/ClusterTableFilters/ClusterFilterOperators";
import SearchIcon from "@mui/icons-material/Search";
import ClusterStatusFilter from "../../components/Tables/ClustersTable/ClusterTableFilters/ClusterStatusFilter";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 200,
    renderCell: ClusterIDCellRender,
    renderHeaderFilter: () => null,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: ClusterStatusCellRender,
    renderHeaderFilter: ClusterStatusFilter,
    filterOperators: clusterContainsStatusArrayOperator,
  },
  {
    field: "maxSlotCount",
    headerName: "Max Slot Count",
    width: 180,
    renderHeaderFilter: () => null,
  },
  {
    field: "taskInstanceType",
    headerName: "Task Instance Type",
    width: 200,
    renderHeaderFilter: () => null,
  },
  {
    field: "state",
    headerName: "State",
    width: 250,
    renderCell: ClusterStateCellRender,
    renderHeaderFilter: ClusterStateFilter,
    filterOperators: clusterContainsStateArrayOperator,
  },
  {
    field: "taskRunningInstanceCount",
    headerName: "Running Instance",
    width: 200,
    renderHeaderFilter: () => null,
  },
  {
    field: "taskRequestedInstanceCount",
    headerName: "Requested Instance",
    width: 200,
    renderHeaderFilter: () => null,
  },
];

const ClustersPage = () => {
  // Pagination
  const clustersFromRedux = useSelector((state) => state.clusters.clustersList);
  const paginationTokenFromRedux = useSelector(
    (state) => state.clusters.nextToken
  );
  const lastPageFetchFromRedux = useSelector(
    (state) => state.clusters.lastPageFetch
  );

  const dispatch = useDispatch();

  const [clustersList, setClustersList] = useState([]);
  const [clustersToDisplay, setClustersToDisplay] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [lastPage, setLastPage] = useState(0);

  // Amount of rows per page variables
  const [amountOfRows, setAmountOfRows] = useState("10");

  // Pagination variables
  const [page, setPage] = useState(1);
  const paginatedClusters = usePagination(
    clustersToDisplay,
    parseInt(amountOfRows) ?? 10
  );
  const [hasNextPage, setHasNextPage] = useState(true);
  const [canDisplayPagination, setCanDisplayPagination] = useState(false);
  const [canRefresh, setCanRefresh] = useState(false);

  const parseAmountOfRows = () => parseInt(amountOfRows) ?? 10;

  // Searching variables
  const [searchBody, setSearchBody] = useState({});

  // Filter variables
  const [clusterStateFilter, setClusterStateFilter] = useState([]);
  const [initialFilterState, setInitialFilterState] = useState();

  const CLUSTER_FILTER_MAP = {
    stateIn: {
      type: "array",
      setter: setClusterStateFilter,
    },
    limit: {
      type: "string",
      setter: setAmountOfRows,
    },
  };

  const FILTER_NAME_MAP = {
    state: {
      contains: "stateIn",
    },
  };

  const OPERATOR_FILTER_NAME_MAP = {
    stateIn: {
      field: "state",
      operator: "contains",
    },
  };

  // URL Params (filter url params) variables
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(updateMenuLink({ linkName: "Clusters" }));

    // Get url params if they are provided
    const params = new URLSearchParams(location.search);
    let urlParams = decodeURLParams(params, CLUSTER_FILTER_MAP);

    async function fetchClusters({ newSearchBody } = {}) {
      await getClusters({
        token: null,
        dispatch: dispatch,
        prevClusters: clustersList,
        nextPage: 1,
        searchBody: newSearchBody,
      });
      setCanDisplayPagination(true);
    }

    if (!urlParams.hasOwnProperty("limit")) {
      urlParams["limit"] = "10";
      CLUSTER_FILTER_MAP["limit"].setter("10");
    }
    if (!urlParams || !Object.keys(urlParams).length) {
      fetchClusters();
    } else {
      // Update the filters with the values
      // from the url route
      const filterItems = new Array();
      for (const [key, value] of Object.entries(urlParams)) {
        if (CLUSTER_FILTER_MAP[key]) {
          CLUSTER_FILTER_MAP[key].setter(value);
          // Add new initial filters to the grid initial state
          if (OPERATOR_FILTER_NAME_MAP[key]) {
            filterItems.push({
              field: OPERATOR_FILTER_NAME_MAP[key].field,
              operator: OPERATOR_FILTER_NAME_MAP[key].operator,
              value: value,
            });
          }
        }
      }
      // Update filters for grid initial state
      setInitialFilterState(filterItems);
      fetchClusters({ newSearchBody: urlParams });
    }
  }, []);

  useEffect(() => {
    setClustersList(clustersFromRedux);
    // Pagination setup
    paginatedClusters.updateData(clustersFromRedux);
    paginatedClusters.updateItemsPerPage(parseAmountOfRows());
    setClustersToDisplay(
      clustersFromRedux.slice(
        parseAmountOfRows() * (page - 1),
        parseAmountOfRows() * page
      )
    );
  }, [clustersFromRedux]);

  useEffect(() => {
    setNextToken(paginationTokenFromRedux);
    if (
      page > 1 &&
      clustersFromRedux.length <= (page - 1) * parseAmountOfRows()
    ) {
      paginatedClusters.jump(page);
      setPage(page);
      setHasNextPage(false);
      setClustersToDisplay(Array(parseAmountOfRows()).fill({}));
    }
  }, [paginationTokenFromRedux]);

  useEffect(() => {
    setLastPage(lastPageFetchFromRedux);
  }, [lastPageFetchFromRedux]);

  // Check if there is a next page
  // of clusters to display
  useEffect(() => {
    setHasNextPage(nextToken || (lastPage && lastPage > page));
  }, [nextToken, lastPage, page]);

  useEffect(() => {
    setCanRefresh(true);
  }, [clustersToDisplay]);

  const onChangePageNumber = async (event, newPage) => {
    setCanDisplayPagination(false);
    setCanRefresh(false);
    // If jumping to the next page, then fetch new clusters
    if (newPage > page && newPage > lastPage) {
      await getClusters({
        token: nextToken,
        dispatch: dispatch,
        prevClusters: clustersList,
        nextPage: newPage,
        searchBody: searchBody,
      });
    }
    // Else update the display clusters from redux
    else {
      setClustersToDisplay(
        clustersFromRedux.slice(
          parseAmountOfRows() * (newPage - 1),
          parseAmountOfRows() * newPage
        )
      );
    }

    setPage(newPage);
    paginatedClusters.jump(newPage);
    setCanDisplayPagination(true);
  };

  const onFilterChange = (filterName, newValue) => {
    const params = new URLSearchParams(location.search);
    let newSearchBody = searchBody;

    // Delete the old value of the filter
    // if any
    if (params.get(filterName)) {
      params.delete(filterName);
    }

    // Check if filter is being added/modified or
    // completely deleted
    if (!newValue.length) {
      delete newSearchBody[filterName];
    } else {
      // Append the filter key,value pair
      // based on the type of the filter
      if (typeof newValue === "string") {
        params.append(filterName, newValue);
      } else {
        for (const key in newValue) {
          params.append(filterName, newValue[key]);
        }
      }
      newSearchBody[filterName] = newValue;
    }
    setSearchBody(newSearchBody);
    updateNavigationHelper({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const updateNavigationHelper = (body) => {
    navigation(body);
  };

  useEffect(() => {
    onFilterChange("stateIn", clusterStateFilter);
  }, [clusterStateFilter]);

  useEffect(() => {
    onFilterChange("limit", amountOfRows);
  }, [amountOfRows]);

  const searchFilters = async (updatedSearchBody = null) => {
    setPage(1);
    await getClusters({
      token: null,
      dispatch: dispatch,
      prevClusters: clustersList,
      nextPage: 1,
      searchBody: updatedSearchBody ?? searchBody,
    });
  };

  const onSearchClick = async (e) => {
    e.preventDefault();
    await searchFilters();
  };

  const onNewAmountOfRowsSelected = async (newValue) => {
    setAmountOfRows(newValue);
    // Use the onFilterChange function to update the url params,
    // but the updating of the searchBody var should be done
    // manually to garantee it is updateded before making a
    // search-api-call directly inside this function
    onFilterChange("limit", newValue);

    // Create deepcopy of the currentSearchBody to avoid
    // collision as the previous onFilterChange function modifies
    // the searchBody as well
    let newSearchBody = JSON.parse(JSON.stringify(searchBody));

    delete newSearchBody["limit"];
    newSearchBody["limit"] = newValue;
    await searchFilters(newSearchBody);
  };

  const getDetailPanelContent = ({ row }) => {
    const CLUSTER_TIMESTAPMS = [
      { title: "Created", time: row.createdTimestampSeconds },
      { title: "Terminated", time: row.terminatedTimestampSeconds },
      { title: "Updated", time: row.updatedTimestampSeconds },
    ];
    return (
      <div className={styles.cluster_detail_panel_div}>
        {CLUSTER_TIMESTAPMS.map((clusterTimestamp, index) => {
          return (
            <Grid
              key={index}
              className={styles.cluster_timestamp_grid}
              container
            >
              <Grid item className={styles.cluster_timestamp_title}>
                <span className={styles.box_title}>
                  {clusterTimestamp.title} Time:
                </span>
              </Grid>
              <Grid item>
                {formatMillisecondsToDateString(clusterTimestamp.time)}
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  };

  const getDetailPanelHeight = () => 150;

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState(
    []
  );

  const onGridFilterChange = React.useCallback((filterModel) => {
    filterModel.items.map((filterItem, index) => {
      const fieldName = filterItem.field;
      const filterOperator = filterItem.operator;
      const newValues = filterItem.value;
      if (FILTER_NAME_MAP[fieldName]) {
        const filterName = FILTER_NAME_MAP[fieldName][filterOperator];
        const setter = CLUSTER_FILTER_MAP[filterName].setter;
        setter(newValues);
      }
    });
  }, []);

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
    setDetailPanelExpandedRowIds(
      newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds
    );
  }, []);

  return (
    <div>
      <div className={styles.title__section}>
        <Typography className={styles.title} variant="h5">
          Clusters
        </Typography>
      </div>

      <div className={styles.action_bar__table_options_section}>
        <div className={styles.filter_bar__button__div}>
          <Button
            variant="contained"
            className={styles.filter_bar__button}
            startIcon={<SearchIcon />}
            type="submit"
            onClick={(e) => onSearchClick(e)}
          >
            Filter
          </Button>
        </div>

        <div className={styles.row_amount_selection__div}>
          <Typography className={styles.row_amount_selection__title}>
            Rows
          </Typography>
          <FormSelectField
            labelID="clusters-screen-select-amount-of-rows"
            value={amountOfRows}
            onChange={(value) => {
              onNewAmountOfRowsSelected(value);
            }}
            options={CLUSTERS_ROWS_PER_PAGE_LIST}
            modalWidth="100px"
            size="small"
            height={35}
          />
        </div>

        <div className={styles.pagiantion__div}>
          <CustomPagination
            currentPage={page}
            onChangePageNumeber={onChangePageNumber}
            hasNext={hasNextPage}
            canDisplay={canDisplayPagination}
          />
        </div>
      </div>

      <div className={styles.data_grid__div}>
        {initialFilterState !== null && initialFilterState !== undefined ? (
          <DataGridPremium
            initialState={{
              filter: {
                filterModel: {
                  items: initialFilterState,
                },
              },
            }}
            filterMode="server"
            onFilterModelChange={onGridFilterChange}
            disableColumnFilter
            unstable_headerFilters
            rows={clustersToDisplay.length ? clustersToDisplay : clustersList}
            columns={columns}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={
              handleDetailPanelExpandedRowIdsChange
            }
            sx={{
              backgroundColor: "white",
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#F9FAFA",
              },
              borderColor: "transparent",
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ClustersPage;
