import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clustersList: [],
  nextToken: null,
  lastPageFetch: 0,
};

export const clustersReducer = createSlice({
  name: "clusters",
  initialState,
  reducers: {
    updateClustersList: (state, action) => {
      state.clustersList = action.payload.clustersList;
      state.nextToken = action.payload.nextToken;
      state.lastPageFetch = action.payload.lastPageFetch;
    },
  },
});

export const { updateClustersList } = clustersReducer.actions;

export default clustersReducer.reducer;
