import { getUrl } from "../utils/shared-functions";
import { getAWSClient } from "./AuthService";

export const createSimTicket = async (content, setMessage) => {
  try {
    const aws = await getAWSClient();
    const domain = await getUrl();
    let url = `${domain}/CreateFeedbackTicket`;
    const simBody = JSON.stringify({
      title: content.title,
      description: content.description,
    });
    const res = await aws.fetch(url, { method: "POST", body: simBody });
    if (res.status === 200) {
      res.json().then((result) => {
        const ticketID = result.ticketID;
        setMessage({
          type: "success",
          messageBody: [
            "A new ticket was successfuly created. Please follow the link to view it: ",
            <a
              href={`https://t.corp.amazon.com/${ticketID}`}
              target="_blank"
              rel="noreferrer"
            >
              Ticket link
            </a>,
          ],
        });
      });
    } else {
      setMessage({
        type: "error",
        messageBody: ["An error occurred while creating the ticket"],
      });
    }
  } catch (e) {
    console.log("creatSIMTicket failed", e);
    setMessage({
      type: "error",
      messageBody: ["An error occurred while creating the ticket"],
    });
  }
};
