import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateMenuLink } from "../../redux/slices/menu-slice";
import styles from "./CloneStep.module.css";
import { Typography, Button } from "@mui/material";
import FormTextField from "../../components/FormFields/FormTextField/FormTextField";
import "../../global.css";
import CreateStep from "../../components/CreateStep/CreateStep";
import { useParams } from "react-router-dom";
import { getStep } from "../../services/StepsService";
import { formatArgumentsToString } from "../../utils/shared-functions";

const CloneStepPage = () => {
  const [stepInfo, setStepInfo] = useState(null);
  const [stepName, setStepName] = useState("");
  const [stepJarLocation, setStepJarLocation] = useState("");
  const [stepArgs, setStepArgs] = useState("");
  const [stepPermissionRole, setStepPermissionRole] = useState("");

  const { stepID } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateMenuLink({ linkName: "Clone Step" }));

    async function getStepHelper() {
      await getStep(stepID, setStepInfo);
    }

    if (stepID) {
      getStepHelper();
    }
  }, []);

  useEffect(() => {
    if (stepInfo && stepInfo.Step) {
      setStepName(stepInfo.Step.Name ?? "");
      setStepPermissionRole(stepInfo.ExecutionRoleArn);
      if (stepInfo.Step.HadoopJarStep) {
        setStepJarLocation(stepInfo.Step.HadoopJarStep.Jar ?? "");
        setStepArgs(formatArgumentsToString(stepInfo.Step.HadoopJarStep.Args));
      }
    }
  }, [stepInfo]);

  return (
    <div className={styles.main}>
      <div className={styles.title__section}>
        <Typography className={styles.title} variant="h5">
          Clone Step
        </Typography>
      </div>

      <div className={styles.add_step__section}>
        {stepInfo && stepInfo.Step ? (
          <CreateStep
            newName={stepName}
            newJarLocation={stepJarLocation}
            newJarArgs={stepArgs}
            newPermissionRole={stepPermissionRole}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CloneStepPage;
