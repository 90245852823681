import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  linkName: "Steps",
};

export const menuReducer = createSlice({
  name: "menu",
  initialState,
  reducers: {
    updateMenuLink: (state, action) => {
      state.linkName = action.payload.linkName;
    },
  },
});

export const { updateMenuLink } = menuReducer.actions;

export default menuReducer.reducer;
